import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

import { AppComponent } from './app.component';

import { CoreModule } from './core/core.module';
import { LayoutModule } from './modules/layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { RoutesModule } from './routes/routes.module';

import localeES from '@angular/common/locales/es';
import { registerLocaleData, KeyValuePipe, APP_BASE_HREF, LocationStrategy } from '@angular/common';

import { SettingsService } from './core/settings/settings.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AuthenticationToken, Token, User } from './models/auth/user';
import { AuthService } from './services/auth/auth.service';

registerLocaleData(localeES, 'es');

const configInitializer = (appConfig: SettingsService) => {
    return () => {
        appConfig.loadAppConfig();
    };
};

// https://github.com/ocombe/ng2-translate/issues/218
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function setBaseURI() {

    let origin = window.location.origin;
    if (!origin.endsWith('/')) {
        origin += '/';
    }

    let baseURI = document.head.baseURI;
    if (!baseURI.endsWith('/')) {
        baseURI += '/';
    }

    let href = window.location.href;
    if (!href.endsWith('/')) {
        href += '/';
    }

    let relativePath = '/' + baseURI.replace(origin, '');
    if (!relativePath.endsWith('/')) {
        relativePath += '/';
    }

    console.log('origin: ' + origin);
    console.log('baseURI: ' + baseURI);
    console.log('href: ' + href);
    console.log('relativePath: ' + relativePath);

    let base = '';
    let currentTokens = new AuthenticationToken();
    const tokens = localStorage.getItem(AuthService.tokensStorageKey);
    if (tokens) {
        Object.assign(currentTokens, JSON.parse(tokens));
    } else {
        currentTokens = null;
    }

    if (currentTokens) {
        console.log('token');
        const token: Token = JSON.parse(window.atob(currentTokens.accessToken.split('.')[1]));
        base = relativePath + token.commerce_code;
    }
    else {
        console.log('empty token');
        const pathRef = href.replace(baseURI, '');
        console.log('pathRef: ' + pathRef);
        const paths = pathRef.split('/');
        if (paths.length > 1 && (pathRef != 'auth/inicio-sesion/' && pathRef != 'auth/') ) {
            base = relativePath + paths[0];
        }
        else {
            base = relativePath;
        }
    }
    console.log('base: ' + base);
    return base;
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        HttpClientModule,
        BrowserAnimationsModule, // required for ng2-tag-input
        CoreModule,
        LayoutModule,
        SharedModule.forRoot(),
        RoutesModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        {
            provide: APP_BASE_HREF,
            useFactory: setBaseURI,
        },
        KeyValuePipe,
        CookieService,
        {
            provide: APP_INITIALIZER,
            useFactory: configInitializer,
            multi: true,
            deps: [
                SettingsService
            ]
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
