import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AppContextService {
    _asyncCall: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    asyncCall$ = this._asyncCall.asObservable();

    constructor() { }

    setNewAsyncCall(val: boolean) {
        this._asyncCall.next(val);
    }
}
