export enum UserFunctions {
    USER_ADD = "P_USER_ADD",
    USER_EDIT = "P_USER_EDIT",
    USER_DELETE = "P_USER_DELETE",
    USER_DETAIL = "P_USER_DETAIL",
    USERS = "P_USERS",
    CONFIGURATIONS = "P_CONFIGURATIONS",
    CONFIGURATION_DETAIL = "P_CONFIGURATION_DETAIL",
    CONFIGURATION_EDIT = "P_CONFIGURATION_EDIT",
    DYNAMICREPORT_EXPORT = "P_DYNAMICREPORT_EXPORT",
    DYNAMICREPORTS = "P_DYNAMICREPORTS",}
