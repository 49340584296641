import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SettingsService {

    private app: any;
    private layout: any;

    constructor(private http: HttpClient) {

        // Layout Settings
        // -----------------------------------
        this.layout = {
            isFixed: true,
            isCollapsed: false,
            isBoxed: false,
            isRTL: false,
            horizontal: false,
            isFloat: false,
            asideHover: false,
            theme: null,
            asideScrollbar: false,
            isCollapsedText: false,
            useFullLayout: false,
            hiddenFooter: false,
            offsidebarOpen: false,
            asideToggled: false,
            viewAnimation: 'ng-fadeInUp',
            editSidebarOpen: false
        };

    }

    getAppSetting(name) {
        return name ? this.app[name] : this.app;
    }
    getLayoutSetting(name) {
        return name ? this.layout[name] : this.layout;
    }

    setAppSetting(name, value) {
        if (typeof this.app[name] !== 'undefined') {
            this.app[name] = value;
        }
    }
    setLayoutSetting(name, value) {
        if (typeof this.layout[name] !== 'undefined') {
            return this.layout[name] = value;
        }
    }

    toggleLayoutSetting(name) {
        return this.setLayoutSetting(name, !this.getLayoutSetting(name));
    }

    loadAppConfig() {
        return this.http.get('assets/config.json')
            .toPromise()
            .then(data => {
                this.app = data;
                this.app.year = ((new Date()).getFullYear());

                // Default Cyclops Desktop settings
                this.app.cyclopsdesktop = Object.assign({
                    "retry_timeout": 3000,
                    "download_link": ""
                }, this.app.cyclopsdesktop )
                
            });
    }

}
