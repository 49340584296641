import { Injectable } from '@angular/core';
import { Customer } from 'src/app/models/customer';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { ToasterNotificationService } from 'src/app/core/toaster/toaster-notification.service';
import { Router } from '@angular/router';
import { CustomerNoAuth } from 'src/app/models/customer-noauth';
import { Credential } from 'src/app/models/credential';
import { CustomerPlan } from 'src/app/models/plan/customer-plan';
import { CustomerOperations } from 'src/app/models/customer-operations';
 
const API_URL = `${environment.apiUrl}/Customer`;

@Injectable({
    providedIn: 'root'
})
export class CustomerService {
    private customer: Customer = null;
    private _customer: BehaviorSubject<Customer> = new BehaviorSubject<Customer>(this.customer);
    credentialsTableFields: Array<any>;
    credentials: Credential[] = [];
    configuration$ = this._customer.asObservable();

    constructor(
        protected http: HttpClient,
        protected router: Router,
        protected toasterService: ToasterNotificationService) { }

        getCustomerByCommerceCodeNoAuth(commerceCode: string){
            return this.http.get<CustomerNoAuth>(`${API_URL}/CustomerNoAuth/${commerceCode}`);
        }

        getCustomerByCommerceCode(commerceCode: string){
            return this.http.get<Customer>(`${API_URL}/${commerceCode}`);
        }

        deleteCredential(customerCode: string, clientId: string) {
            return this.http.delete(`${API_URL}/${customerCode}/Credentials/${clientId}`);
        }
    
    
        addCredential(customerCode: string) {
            return this.http.post<Credential>(`${API_URL}/${customerCode}/Credentials`, customerCode);
        }
    
        updateCredential(customerCode: string, clientId: string, newalias: string) {
            const updatedCredential = {
                clientId,        
                newalias, 
                };
                

            return this.http.post<Credential>(`${API_URL}/Credentials/Alias`, updatedCredential);
        }

        getCredentialByCustomerCode(customerCode: string) {
            return this.http.get<Credential[]>(`${API_URL}/${customerCode}/Credentials`);
        }

        getCustomerPlanByCustomer(customerCode: string) {
            return this.http.get<CustomerPlan>(`${API_URL}/${customerCode}/Plan`);
        }

        getSelf() {
            return this.http.get<CustomerOperations>(`${API_URL}/Self`);
        }
    
}
