import { Injectable } from '@angular/core';
import { UserFunctions } from 'src/app/models/auth/user-functions';
import { AuthService } from 'src/app/services/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const API_URL = environment.apiUrl;

@Injectable()
export class MenuService {

    menuItems: Array<any>;
    reportItems: Array<any>;
    logItems: Array<any>;

    constructor(private http: HttpClient, private auth: AuthService) {
        this.menuItems = [];
    }

    addMenu(items: Array<{
        text: string,
        heading?: boolean,
        link?: string,     // internal route links
        elink?: string,    // used only for external links
        target?: string,   // anchor target="_blank|_self|_parent|_top|framename"
        icon?: string,
        alert?: string,
        submenu?: Array<any>,
        function?: UserFunctions,
        order?: number
    }>) {
        this.addMenuItems(items);
        this.addDynamicMenu();
    }

    addDynamicMenu() {
        const role = this.auth.getRole;
        if ( role.functions.includes(UserFunctions.DYNAMICREPORTS)) {
            return this.http
                .get<Array<{
                    name: string,
                    description: string,
                    menuIcon: string
                    order?: number
                }>>(`${API_URL}/report`)
                .subscribe(data => {
                    const Reportes = {
                        text: 'Reportes',
                        link: '/reportes',
                        icon: 'fas fa-chart-pie',
                        submenu: [],
                        function: UserFunctions.DYNAMICREPORTS
                    };
                    data.forEach(item => {
                        Reportes.submenu.push({
                            text: item.description,
                            link: `/reportes/${item.name}`,
                            //function: `${UserFunctions.Reportes}_${item.name}`,
                            order: item.order
                        });
                    });
                    this.reportItems = data;
                    this.addMenuItems([Reportes]);
                });
        }

    }

    private addMenuItems(items: Array<{
        text: string,
        heading?: boolean,
        link?: string,     // internal route links
        elink?: string,    // used only for external links
        target?: string,   // anchor target="_blank|_self|_parent|_top|framename"
        icon?: string,
        alert?: string,
        submenu?: Array<any>,
        function?: UserFunctions,
        order?: number
    }>) {
        const role = this.auth.getRole;
        items.sort((a, b) => a.order && b.order ? a.order - b.order : 0);
        items.forEach(item => {

            if (item.submenu) {
                item.submenu.sort((a, b) => a.order && b.order ? a.order - b.order : 0);
                item.submenu.forEach(subitem => {
                    if (subitem.submenu) {
                        subitem.submenu.sort((a, b) => a.order && b.order ? a.order - b.order : 0);
                    }
                });
            }
        });
        items.forEach((item) => {
            const auxMenu = JSON.parse(JSON.stringify(item));

            if (auxMenu.submenu && role) {
                const auxSubMenu = JSON.parse(JSON.stringify(auxMenu.submenu));
                item.submenu.forEach(submenu => {
                    if (submenu.function && !role.functions.includes(submenu.function)) {
                        const i = auxSubMenu.findIndex(e => JSON.stringify(e) === JSON.stringify(submenu));
                        auxSubMenu.splice(i, 1);
                    }
                });
                auxMenu.submenu = auxSubMenu;
            }
            else if (role && auxMenu.function && !role.functions.includes(auxMenu.function)) {
                return;
            }


            if (auxMenu.submenu && auxMenu.submenu.length === 0) {
                return;
            }
            this.menuItems.push(auxMenu);

        });
        this.reorderMenu();
    }

    getMenu() {
        return this.menuItems;
    }
    getMenuJson() {
        return this.http.get<any>('assets/menu/menu.json');
    }

    setMenu(menu: Array<any>) {
        this.menuItems = menu;
    }

    private reorderMenu() {
        this.menuItems.sort((a, b) => a.order && b.order ? a.order - b.order : 0);
    }
}
