import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/core/settings/settings.service';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AssemblyInfoService } from 'src/app/services/Information/Assembly-Info.services';
import { assemblyInfo } from 'src/app/models/assemblyInfo';

const API_URL = `${environment.apiUrl}/User`;
@Component({
    selector: '[app-footer]',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {
    public assemblyInfo: assemblyInfo = <any>{}

    constructor(
        public settings: SettingsService,
        private assemblyInfoService : AssemblyInfoService, 
    ) { }


    ngOnInit() {
        this.assemblyInfoService.getBasVersion().subscribe(data => {this.assemblyInfo = data});
    }

}
