<div class="item user-block" *ngIf="userBlockIsVisible()">
    <!-- Name and Job-->

    <div class="user-block-info">
<br/>
 
        <div *ngIf="commerceLogo" class="p-2">
            <em class="user-block-picture">
                <img [src]="commerceLogo" class="img-thumbnail" title="Logo del Comercio"/>
            </em>
        </div> 
        <div *ngIf="!commerceLogo">
            <img src="assets/images/no-logo.jpg" class="img-thumbnail" title="Logo del Comercio" >
           <!-- <em class="fa fa-3x fa-user"></em> -->
        </div> 
        <span class="user-block-name">{{username}}</span>
        <span class="user-block-role">{{rolename}}</span>
    </div>
</div>
