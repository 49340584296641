import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, pipe } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { AppContextService } from 'src/app/core/context/app-context.service';

@Injectable({
    providedIn: 'root'
})
export class RequestLoadingInterceptor implements HttpInterceptor {

    constructor(private appContextService: AppContextService) { }

    requestCounter = false;
    reqCount = 0;

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.beginRequest();
        this.reqCount++;

        return next.handle(req).pipe(

            finalize(() => {
                this.reqCount--;
                if (this.reqCount === 0) {
                    this.endRequest();
                }
            })

        );

    }

    beginRequest() {
        this.requestCounter = true;
        if (this.requestCounter) {
            this.appContextService.setNewAsyncCall(true);
            // console.log('request init ');
        }
    }

    endRequest() {
        this.requestCounter = false;
        if (!this.requestCounter) {
            this.appContextService.setNewAsyncCall(false);
            // console.log('request end');
        }
    }
}
