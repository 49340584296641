import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from 'src/app/services/auth/auth.service';
import { ToasterNotificationService } from 'src/app/core/toaster/toaster-notification.service';
import { RouterService } from 'src/app/core/router/router.service';
import { ActivatedRoute } from '@angular/router';

const genericErrorMessage = 'Algo salió mal :(';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthService,
        private routerService: RouterService,
        private route: ActivatedRoute,
        private toaster: ToasterNotificationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                const login = this.routerService.getCurrentUrl().split('/').find(e => e.startsWith('auth/inicio-sesion'));
                if (login) {
                    this.toaster.showToast('error', '', 'Usuario o contraseña incorrectos');
                }
                this.authenticationService.redirectToLogin();
            }
            if (err.status === 403) {
                this.toaster.showToast('error', '', 'No tiene permisos para realizar esta acción :(');
            }
            if (err.status === 400) {
                // err.error = JSON.parse(JSON.stringify(err.error));
                var message = '';
                // si se recibe un array de errores
                if (err.error.details instanceof Array && err.error.details.length >0) {
                    for (const { index, value } of err.error.details.map((value, index) => ({ index, value }))) {
                        if (index >= 5) {
                            // muestra los primeros 5 errores..
                            message += 'Existen mas errores...' + '<br/>';
                            break;
                        }
                        message += value + '<br/>';

                    }
                }
                else
                //sino es array muestra el toast para el error unico
                {
                    message = err.error.message ? err.error.message : genericErrorMessage;
                }
                this.toaster.showToast('error', 'Error', `${message}`);
            }
            if (err.status === 500) {
                this.toaster.showToast('error', 'Error', `${genericErrorMessage}`);
            }

            // const error = err.error || err.statusText;
            return throwError(err);
        }));
    }
}
