import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

class Toaster {
    type: string | null;
    title: string | null;
    text: string | null;
}

@Injectable({
    providedIn: 'root'
})
export class ToasterNotificationService {
    private toaster: Toaster = null;
    private _toaster: BehaviorSubject<Toaster> = new BehaviorSubject<Toaster>(this.toaster);
    toaster$ = this._toaster.asObservable();

    constructor() { }

    setValue(toast: any) {
        this.toaster = toast;
        this._toaster.next(this.toaster);
    }
    cleanToaster() {
        this.toaster = null;
        this._toaster.next(this.toaster);
    }

    showToast(type: string, title: string, text: string) {
        this.toaster = {
            type,
            title,
            text
        };
        this._toaster.next(this.toaster);
        // this.cleanToaster();
    }
}
