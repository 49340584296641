import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, empty, Observable, throwError } from 'rxjs';

import { AuthService } from 'src/app/services/auth/auth.service';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { RefreshToken } from '../../models/auth/user';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private authService: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const tokens = this.authService.getTokens();;
        if (tokens) {
            request = this.addToken(request, tokens.accessToken);
        }

        return next.handle(request).pipe(catchError(error => {
            const tokens = this.authService.getTokens();
            if (tokens &&
                error instanceof HttpErrorResponse && error.status === 401) {
                return this.handle401Error(request, next);
            } else {
                return throwError(error);
            }
        }));
    }

    private addToken(request: HttpRequest<any>, token: string) {
        return request.clone({
            setHeaders: {
                'Authorization': `Bearer ${token}`
            }
        });
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            return this.authService.refreshToken().pipe(
                switchMap((tokens: RefreshToken) => {
                    this.isRefreshing = false;
                    this.refreshTokenSubject.next(tokens.accessToken);
                    if (tokens && tokens.accessToken) {
                        return next.handle(this.addToken(request, tokens.accessToken));
                    }
                    else {
                        this.authService.redirectToLogin();
                        return empty();
                    }
                }));

        } else {
            return this.refreshTokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(jwt => {
                    return next.handle(this.addToken(request, jwt));
                }));
        }
    }
}
