<div class="container" *ngIf="showLoader">
    <div class="loader">
        <div class="loader-inner">
            <div class="loader-content">
                <div class="sk-circle">
                    <div class="sk-circle-dot"></div>
                    <div class="sk-circle-dot"></div>
                    <div class="sk-circle-dot"></div>
                    <div class="sk-circle-dot"></div>
                    <div class="sk-circle-dot"></div>
                    <div class="sk-circle-dot"></div>
                    <div class="sk-circle-dot"></div>
                    <div class="sk-circle-dot"></div>
                    <div class="sk-circle-dot"></div>
                    <div class="sk-circle-dot"></div>
                    <div class="sk-circle-dot"></div>
                    <div class="sk-circle-dot"></div>
                </div>
            </div>
        </div>
    </div>
</div>
