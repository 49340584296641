import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';

import { UserblockService } from './userblock.service';

@Component({
    selector: 'app-userblock',
    templateUrl: './userblock.component.html',
    styleUrls: ['./userblock.component.scss']
})
export class UserblockComponent implements OnInit {
    username: string;
    commerceLogo: any;
    commerceCode: string;
    rolename: string;
    constructor(public userblockService: UserblockService, public auth: AuthService) {
        this.auth.username$.subscribe(data => 
            this.username = data);
        this.auth.commerceLogo$.subscribe(data => 
            this.commerceLogo = data);
        this.auth.rolename$.subscribe(data => 
            this.rolename = data);
        this.auth.commerceCode$.subscribe(data => 
            this.commerceCode = data);
    }

    ngOnInit() {
    }

    userBlockIsVisible() {
        return this.userblockService.getVisibility();
    }

}
